import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DestroyRef, Injectable, Injector } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { io, Socket } from 'socket.io-client';
import { UserRole } from 'src/definitions/user-role.enum';
import { UserStatus } from 'src/definitions/user-status.enum';
import { CredentialsInterface } from 'src/definitions/user.interface';
import { LoginResponse } from 'src/models/login-response';
import { UserData } from 'src/models/user';
import { ApiService } from 'src/services/api.service';
import { CustomSnackbarService } from 'src/services/custom-snackbar.service';
import { UserService } from 'src/services/user.service';
import { getStorageObject, removeStorageObject, setStorageObject } from 'src/utils/storage-manager';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * API path
   */
  private path = '/auth';

  /**
   * Url to redirect after login
   */
  redirectUrl?: string | null;

  /**
   * The Observable signaling that the user data has been loaded
   */
  userDataReady: Subject<UserData> = new Subject<UserData>();

  translateService: TranslateService | undefined;

  socket: Socket | undefined;

  constructor(
    private http: HttpClient,
    private snackBarService: CustomSnackbarService,
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
    private inj: Injector,
    private destroyRef: DestroyRef
  ) {
    this.path = this.apiService.getApiUrl() + this.path;
  }

  /**
   * This method calls the API endpoint in order to get a cookie when loginData are correct credentials
   * @param credentials - An object with the keys 'email' and 'password'
   */
  login(credentials: CredentialsInterface, role: UserRole): Observable<boolean> {
    const loginOkSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    const loginData: CredentialsInterface = {
      email: credentials.email,
      password: credentials.password
    };

    this.http
      .post<IJsonObject>(`${this.path}/cookie`, loginData)
      .pipe(map((response: any) => Deserialize(response, () => LoginResponse)))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (data: any) => {
          this.successLoginHandler(loginOkSubject, data);
          if (!this.redirectUrl) {
            this.redirectUrl = this.redirectURLAfterLogin(role);
          }
        },
        () => {
          // Check reason of the error
          this.userService
            .getStatus(loginData)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
              (userStatus) => this.errorLoginStatusHandler(userStatus, loginOkSubject),
              (_error) => this.errorLoginHandler(_error, loginOkSubject)
            );
        }
      );

    return loginOkSubject.asObservable();
  }

  errorLoginHandler(error: HttpErrorResponse, loginOkSubject: BehaviorSubject<boolean>): void {
    this.translateService = this.inj.get(TranslateService);

    if (error?.status === 404) {
      this.snackBarService.open(this.translateService?.instant('AuthService.IncorrectEmailPassword'), '', {
        duration: 3000
      });
    } else {
      this.snackBarService.showError(error);
    }
    loginOkSubject.complete();
  }

  errorLoginStatusHandler(status: UserStatus, loginOkSubject: BehaviorSubject<boolean>): void {
    this.translateService = this.inj.get(TranslateService);

    if (status === UserStatus.DISABLED) {
      this.snackBarService.open(this.translateService?.instant('AuthService.DisabledUser'), '', {
        duration: 3000
      });
    } else if (status === UserStatus.PENDING) {
      this.snackBarService.open(this.translateService?.instant('AuthService.PendingUser'), '', {
        duration: 3000
      });
    }
    loginOkSubject.complete();
  }

  /**
   * Handler for the login successful event after logging in
   * @param {LoginResponse} data Login response, with data about login or not
   */
  successLoginHandler(loginOkSubject: BehaviorSubject<boolean>, data: LoginResponse): void {
    if (data.loginOk) {
      this.fillOperatorData()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.userDataReady.next(<UserData>AuthService.getUserData());
          loginOkSubject.next(true);
        });
    } else {
      loginOkSubject.complete();
    }
  }

  /**
   * Fill operator data in browser storage
   */
  fillOperatorData(): Observable<UserData> {
    const dataLoaded = new Subject<UserData>();
    this.userService
      .profile()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user: UserData) => {
        setStorageObject(
          'userData',
          Serialize(user, () => UserData),
          'local'
        );
        this.initializeSocket(user);
        dataLoaded.next(user);
      });

    return dataLoaded.asObservable();
  }

  /**
   * This method invalidates current cookie
   */
  deleteCookie(): void {
    this.http
      .delete(`${this.path}/cookie`)
      .pipe(catchError((err: HttpErrorResponse) => this.snackBarService.showError(err)))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  /**
   * Whether the user has a valid token or not
   * @returns {boolean}
   */
  loggedIn(): boolean {
    return !!getStorageObject('userData');
  }

  /**
   * Check if the user has the specified role
   * @param {UserRole} role - The role to check
   * @returns {boolean} - True if the user has the specified role, false otherwise
   */
  hasSameRole(role: UserRole): boolean {
    const userData = AuthService.getUserData();
    const userHasRole = userData?.userType === role;

    return userHasRole;
  }

  /**
   * Remove the access token cookie as well as userData from browser storage and redirects the user to login page
   */
  logout(deleteCookie = true): void {
    if (deleteCookie) {
      this.deleteCookie();
    }
    this.socket?.disconnect();
    removeStorageObject('userData');
    this.userService.user$ = of(undefined);
  }

  /**
   * Custom login handler. Emits events to inform external components the login has been successful or not
   */
  successLoginInfoAndRedirect(loginOk: boolean, role: UserRole, isModal = false): void {
    this.translateService = this.inj.get(TranslateService);
    let route: string;
    if (this.redirectUrl) {
      route = this.redirectUrl;
    } else {
      route = this.redirectURLAfterLogin(role);
    }

    if (loginOk && this.hasSameRole(role)) {
      if (!isModal) {
        this.router.navigateByUrl(route);
      }

      this.redirectUrl = null;
    } else if (loginOk && !this.hasSameRole(role)) {
      //Logout and remove userData if the user has the wrong role
      this.logout();

      this.snackBarService.open(this.translateService?.instant('AuthService.WrongPermissions'), '', {
        duration: 3000
      });
    } else {
      this.snackBarService.open(this.translateService?.instant('AuthService.WrongCredentials'), '', {
        duration: 3000
      });
    }
  }

  /**
   * URL to redirect the user after login
   * depending on the user role in case
   * a previous redirect URL doesn't exist
   * @param role
   */
  redirectURLAfterLogin(role: UserRole): string {
    const roleToURL: { [key in UserRole]: string } = {
      [UserRole.CLIENT]: '/home',
      [UserRole.OPERATOR]: '/operator',
      [UserRole.ADMIN]: '/admin/operators'
    };

    return roleToURL[role];
  }

  /**
   * Retrieves the user data from the JWT
   *
   * @return {Object} User data
   */
  static getUserData(): UserData | undefined {
    const user: IJsonObject = getStorageObject('userData');
    if (user) {
      return Deserialize(user, () => UserData);
    } else {
      return undefined;
    }
  }

  initializeSocket(user: UserData): void {
    const socketUrl = this.apiService.getApiUrl();
    if (user && user.id) {
      this.socket = io(socketUrl.replace('/api', ''), {
        query: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_id: user.id.toString()
        }
      });
    }
  }

  getSocket(): Socket | undefined {
    return this.socket;
  }
}
