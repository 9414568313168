/**
 * Enum representing the roles that a user can have.
 */

export enum UserStatus {
  /**
   * The user is active
   */
  ACTIVE = 'ACTIVE',
  /**
   * The user is pending
   */
  PENDING = 'PENDING',
  /**
   * The user is disabled
   */
  DISABLED = 'DISABLED'
}
