import { autoserializeAs } from 'dcerialize';

/**
 * Login response structure
 */
export class LoginResponse {
  /**
   * If the login was successful or not
   */
  @autoserializeAs(() => Boolean, 'login_ok') loginOk: boolean | undefined;

  /**
   * Login access token
   */
  @autoserializeAs(() => String, 'access_token') accessToken: string | undefined;

  /**
   * If the user is blocked or not
   */
  @autoserializeAs(() => Boolean, 'blocked') blocked: boolean | undefined;

  /**
   * Hack to prevent the mistaken assignment of objects from one class to a different class
   */
  private readonly _loginResponse = true;
}
